<!--试块留置状态-->
<template>
    <div class="testBlockStayState tableList" v-loading="loading">
        <currencyTable
            class="tableHeader"
            :table-data="tableData"
            ref="currencyTable"
            @viewTestBlock="viewTestBlock"
        >
            <div slot="queryCriteria" class="queryCriteria">
                <div class="queryList">
                    <h4>时间段：</h4>
                    <el-date-picker
                        class="time-range-inp"
                        v-model="queryDate"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="selectTimeQuery"
                    >
                    </el-date-picker>
                </div>
            </div>
        </currencyTable>
    </div>
</template>

<script>
import currencyTable from '@/components/table/index.vue';

export default {
    // 运输调价列表
    name: 'test-block-stay-state',
    components: {
        currencyTable,
    },
    props: {
        vueFormParams: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            queryDate: [],
            tableData: {
                isShowNo: false,
                generalQuery: true,
                showCheckBox: false,
                headerData: [{
                    title: '任务编号',
                    field: 'task_number',
                    width: '180',
                }, {
                    title: '客户名称',
                    field: 'customer_name',
                    width: '180',
                }, {
                    title: '工程名称',
                    field: 'project_name',
                    width: '180',
                }, {
                    title: '工程类型',
                    field: 'project_type_name',
                    width: '120',
                }, {
                    title: '工程地址',
                    field: 'project_address',
                    width: '180',
                }, {
                    title: '施工部位',
                    field: 'place_detail',
                    width: '100',
                }, {
                    title: '到货时间',
                    field: 'arrival_time',
                    width: '160',
                }, {
                    title: '强度等级',
                    field: 'strength_grade_name',
                    width: '80',
                }, {
                    title: '特殊要求',
                    field: 'special_require_name',
                }, {
                    title: '计划方量',
                    field: 'plan_quantity',
                }, {
                    title: '试块组数',
                    field: 'test_block_group',
                }, {
                    title: '龄期',
                    field: 'instar',
                }, {
                    title: '试块数',
                    field: 'test_block',
                    style: 'color: blue; text-decoration: underline; cursor: pointer;',
                    link: {
                        method: 'viewTestBlock',
                    },
                }],
                requestUrl: '/production/formulablockpressure/test_block_statistics',
                requestParameter: '',
            },
        };
    },
    computed: {},
    watch: {},
    created() {
        this.getData();
    },
    mounted() { },
    destroyed() {},
    methods: {
        getData() {
            const curDate = new Date();
            const beginDate = curDate.getFullYear() + '-' + (curDate.getMonth() + 1) + '-' + curDate.getDay() + ' 00:00:00';
            const endDate = curDate.getFullYear() + '-' + (curDate.getMonth() + 1) + '-' + curDate.getDay() + ' 23:59:59';
            this.queryDate[0] = beginDate;
            this.queryDate[1] = endDate;
            this.tableData.requestParameter = `&beginTime=${this.queryDate[0]}&endTime=${this.queryDate[1]}`;
        },
        // 选择时间查询
        selectTimeQuery() {
            this.tableData.requestParameter = `&beginTime=${this.queryDate[0]}&endTime=${this.queryDate[1]}`;
            this.$refs.currencyTable.getTableData();
        },
        // 查看试块
        viewTestBlock(row) {
            this.$toast({
                title: true,
                text: '试块列表',
                type: 'eject',
                width: '15.4rem',
                t_url: 'tableList/index',
                viewPosition: 'view',
                extr: {
                    code: {
                        TableCode: 'test_block',
                        QueryString: 'task_number=' + row.task_number + '&instar=' + row.instar,
                    },
                },
            });
        },
    },
};
</script>
<style lang="stylus">
.testBlockStayState
    .queryCriteria .queryList
        display flex
        align-items center
    .time-range-inp
        width 3.6rem!important;
    >>> .el-link
        color: blue;
        text-decoration: underline;
        cursor: pointer;
        &:after
            display none!important;
    .el-input-group
        overflow hidden

.main_theme1
    .el-button
        background #022782!important;
</style>
